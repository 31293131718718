<template>
    <ValidationObserver ref="login">
        <h2 class="fonte-primaria negrito">Entrar no portal</h2>
        <unicom-linha espacamento class="linha-checkbox">
            <unicom-coluna>
                <unicom-checkbox v-model="estrangeiro" texto="Sou estrangeiro" unico />
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-login primeira-linha" v-if="erro">
            <unicom-coluna :md="12">
                <div class="fonte-secundaria texto erro">
                    <p v-html="mensagemErro"></p>
                </div>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-login">
            <unicom-coluna :md="12">
                <validation-provider rules="required" mode="eager" v-slot="{ errors }" v-if="!estrangeiro">
                    <unicom-campo-texto
                        :mask="['###.###.###-##', '##.###.###/####-##']"
                        apenasNumero
                        v-model="usuario.login"
                        :titulo="'CPF ou CNPJ'"
                        :placeholder="'Insira o CPF ou CNPJ'"
                        :mensagemErro="errors[0]"
                    />
                </validation-provider>
                <validation-provider rules="required" mode="eager" v-slot="{ errors }" v-else>
                    <unicom-campo-texto
                        :mask="['A######-A']"
                        v-model="usuario.login"
                        :titulo="'RNE'"
                        :placeholder="'Insira o RNE'"
                        :mensagemErro="errors[0]"
                    />
                </validation-provider>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-login ">
            <unicom-coluna :md="12">
                <validation-provider ref="pass-check" rules="required" mode="eager" v-slot="{ errors }">
                    <div class="group-password">
                        <unicom-campo-texto
                            class="campo"
                            v-model="usuario.senha"
                            :titulo="'Senha'"
                            :placeholder="'Insira a senha'"
                            :mensagemErro="errors[0]"
                            ladoIcone="direito"
                            :tipoCampo="password_type"
                        />
                        <unicom-icone class="icone" :nome="password_icon" :tamanho="22" @click="toggleTypePassword()" />
                    </div>
                </validation-provider>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-login">
            <unicom-coluna>
                <unicom-botao :tipo="'primario'" bloco @click="entrar()" :altura="40">
                    ENTRAR
                </unicom-botao>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-login">
            <unicom-coluna>
                <small class="fonte-secundaria texto" @click="$emit('sem-acesso')"
                    >Não consigo acessar minha conta</small
                >
            </unicom-coluna>
        </unicom-linha>
    </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        erro: { type: Boolean, required: false, default: false },
        mensagemErro: { type: String, required: false }
    },
    data() {
        return {
            toggle: true,
            password_icon: "eye",
            password_type: "password",

            estrangeiro: false,

            usuario: { login: "", senha: "" }
        };
    },
    methods: {
        async entrar() {
            const validacaoLogin = await this.$refs.login.validate();

            const usuario = {
                grant_type: "password",
                username: this.usuario.login.replace(/([./-])/g, ""),
                password: this.usuario.senha
            };

            if (validacaoLogin) this.$emit("login", usuario);
        },
        toggleTypePassword() {
            if (this.toggle) {
                this.password_icon = "eye-off";
                this.password_type = "text";
            } else {
                this.password_icon = "eye";
                this.password_type = "password";
            }

            this.toggle = !this.toggle;
        }
    },
    watch: {
        estrangeiro() {
            this.usuario.login = "";
            this.usuario.senha = "";
        },
        erro() {
            if (this.erro) {
                this.usuario.senha = "";
                this.$refs["pass-check"].reset();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 32px;
    line-height: 55px;
    color: #00995d;
}

.linha-checkbox {
    margin-bottom: 24px;
}

.linha-login {
    margin-top: 24px;

    & .erro {
        box-sizing: border-box;
        height: 72px;
        padding: 16px 24px;
        border: 1px solid #ed1651;
        border-radius: 5px;
        color: #ed1651;
        line-height: 20px;
    }

    &.primeira-linha {
        margin-top: 0;
    }

    .group-password {
        display: inline-flex;
        color: #707070;
        width: 100%;

        .campo {
            width: 100%;
        }

        .icone {
            position: absolute;
            top: 33px;
            right: 25px;
            cursor: pointer;
        }
    }
}

small {
    color: #1492e6;
    cursor: pointer;
}
</style>
