import Axios from "axios";

var RecuperarSenhaServiceMixin = {
    methods: {
        getEmail(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/usuario/obtem-email-parcial",
                    params: args
                })
            );
        },

        iniciarRecuperacaoSenha(data) {
            return Axios.post(this.CriarUrl({ resource: "api/account/start-password-recovery" }), data, {
                withCredentials: true
            });
        }
    }
};

export default RecuperarSenhaServiceMixin;
