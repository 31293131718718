import { render, staticRenderFns } from "./ListagemUnimed.vue?vue&type=template&id=476f6872&scoped=true&"
import script from "./ListagemUnimed.vue?vue&type=script&lang=js&"
export * from "./ListagemUnimed.vue?vue&type=script&lang=js&"
import style0 from "./ListagemUnimed.vue?vue&type=style&index=0&id=476f6872&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476f6872",
  null
  
)

export default component.exports