<template>
    <div>
        <unicom-linha espacamento>
            <unicom-coluna class="fonte-secundaria subtitulo linha-icone">
                <div class="icone-container" @click="voltar()">
                    <unicom-icone :nome="'arrow-left'" :tamanho="22" />
                </div>
                <div class="label-container" @click="voltar()">
                    <p>Voltar</p>
                </div>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento v-if="erro" class="linha-login primeira-linha">
            <unicom-coluna :md="12">
                <div class="fonte-secundaria texto erro">
                    <p>Usuário não encontrado!</p>
                    <p>Por favor, tente novamente.</p>
                </div>
            </unicom-coluna>
        </unicom-linha>

        <ValidationObserver ref="login">
            <h2 class="fonte-primaria negrito">{{ titulo }}</h2>
            <template v-if="index === 0">
                <unicom-linha espacamento class="linha-checkbox">
                    <unicom-coluna>
                        <unicom-checkbox v-model="estrangeiro" texto="Sou estrangeiro" unico />
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha espacamento class="linha-login primeira-linha">
                    <unicom-coluna>
                        <validation-provider rules="required" mode="eager" v-slot="{ errors }" v-if="!estrangeiro">
                            <unicom-campo-texto
                                :mask="['###.###.###-##', '##.###.###/####-##']"
                                apenasNumero
                                v-model="documento"
                                :titulo="'CPF ou CNPJ *'"
                                :placeholder="'Insira o CPF ou CNPJ'"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                        <validation-provider rules="required" mode="eager" v-slot="{ errors }" v-else>
                            <unicom-campo-texto
                                v-model="documento"
                                :mask="['A######-A']"
                                :titulo="'RNE *'"
                                :placeholder="'Insira o RNE'"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha espacamento class="linha-login">
                    <unicom-coluna>
                        <unicom-botao
                            :tipo="'primario'"
                            bloco
                            @click="continuar()"
                            :desabilitado="!campoPreenchido"
                            :altura="40"
                        >
                            CONTINUAR
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </template>
            <template v-if="index === 1">
                <unicom-linha espacamento class="linha-checkbox">
                    <div class="linha-recuperar-senha">
                        <p class="fonte-secundaria texto">
                            Deseja enviar um link para alterar sua senha no e-mail abaixo?
                        </p>
                    </div>
                    <div class="linha-recuperar-senha">
                        <p class="fonte-secundaria subtitulo">{{ email }}</p>
                    </div>
                    <div class="linha-recuperar-senha">
                        <small class="fonte-secundaria texto" @click="entrarContato()"
                            >Não tenho acesso a esse e-mail</small
                        >
                    </div>
                </unicom-linha>
                <unicom-linha espacamento class="linha-login">
                    <unicom-coluna>
                        <unicom-botao :tipo="'primario'" bloco @click="enviarLink()" :altura="40">
                            ENVIAR LINK
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </template>
            <template v-if="index === 2 && !sucesso">
                <unicom-linha espacamento class="linha-checkbox">
                    <p class="fonte-secundaria texto">Entre em contato com o administrador da sua Unimed.</p>
                </unicom-linha>
                <unicom-linha espacamento class="linha-login">
                    <unicom-coluna>
                        <unicom-botao :tipo="'primario'" bloco @click="voltarLogin()" :altura="40">
                            VOLTAR AO LOGIN
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </template>
            <template v-if="index === 2 && sucesso">
                <unicom-linha espacamento class="linha-checkbox">
                    <p class="fonte-secundaria texto">Enviamos no e-mail as instruções para você alterar sua senha</p>
                </unicom-linha>
                <unicom-linha espacamento class="linha-login">
                    <unicom-coluna>
                        <unicom-botao :tipo="'primario'" bloco @click="voltarLogin()" :altura="40">
                            VOLTAR AO LOGIN
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </template>
        </ValidationObserver>

        <ModalErro v-model="showModalError" :erroObj="modalErroData" @fechar="showModalError = false" />

        <unicom-modal :value="loading" :largura="320">
            <div slot="body">
                <unicom-loading v-model="loading" style="margin-top:25px;" mensagem="Enviando e-mail..." />
            </div>
        </unicom-modal>
    </div>
</template>

<script>
import RecuperarSenhaServiceMixin from "../services/RecuperarSenhaServiceMixin";
import ModalErro from "../../../shared/tratamentoErro/modalErro/ModalErro";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    mixins: [RecuperarSenhaServiceMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        ModalErro
    },
    data() {
        return {
            index: 0,
            erro: false,
            sucesso: false,
            estrangeiro: false,

            documento: "",
            email: "",

            loading: false,

            showModalError: false,
            modalErroData: {}
        };
    },
    methods: {
        async continuar() {
            const validacaoLogin = await this.$refs.login.validate();
            const username = { username: this.documento.replace(/([./-])/g, "") };

            if (validacaoLogin) this.getEmailUsuario(username);
        },
        getEmailUsuario(documento) {
            this.erro = false;

            this.getEmail(documento)
                .then(response => {
                    this.index += 1;
                    this.email = response.data;
                })
                .catch(() => (this.erro = true));
        },
        entrarContato() {
            this.index += 1;
            this.sucesso = false;
        },
        enviarLink() {
            const username = { username: this.documento.replace(/([./-])/g, "") };
            this.loading = true;

            this.iniciarRecuperacaoSenha(username)
                .then(() => {
                    this.index += 1;
                    this.sucesso = true;
                })
                .catch(err => {
                    this.modalErroData = err.response.data.erro;
                    this.showModalError = true;
                })
                .finally(() => (this.loading = false));
        },
        voltarLogin() {
            this.documento = "";
            this.estrangeiro = false;
            this.index = 0;
            this.sucesso = false;
            this.$emit("retornar");
        },
        voltar() {
            if (this.index === 0) {
                this.voltarLogin();
            } else if (this.index < 2) {
                this.index = this.index - 1;
            } else {
                this.index = this.index - 1;
                this.sucesso = false;
            }
        }
    },
    watch: {
        estrangeiro() {
            this.documento = "";
        }
    },
    computed: {
        titulo() {
            if (this.index < 2) {
                return "Recuperar senha";
            } else if (this.sucesso) {
                return "O email foi enviado!";
            } else {
                return "Entre em contato";
            }
        },
        campoPreenchido() {
            const login = this.documento.replace(/([./-])/g, "");

            if (this.estrangeiro) return login.length == 8;
            else return login.length == 11 || login.length == 14;
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-icone {
    color: #bebebe;
    height: 24px;
    margin-bottom: 12px;

    .icone-container {
        display: block;
        float: left;
        width: 24px;
        cursor: pointer;
    }

    .label-container {
        display: block;
        float: left;
        width: calc(100% - 24px);

        p {
            color: #bebebe;
            margin-top: 3px;
            margin-left: 16px;
            cursor: pointer;
        }
    }
}

h2 {
    font-size: 32px;
    line-height: 55px;
    color: #00995d;
}

p {
    margin: 16px 0 16px 16px;
    color: #60656c;
}

.linha-checkbox {
    margin-bottom: 24px;

    .linha-recuperar-senha {
        width: 100%;
    }
}

.linha-login {
    margin-top: 24px;

    &.primeira-linha {
        margin-top: 0;
    }

    & .erro {
        box-sizing: border-box;
        height: 72px;
        padding: 0px 16px;
        border: 1px solid #ed1651;
        border-radius: 5px;
        line-height: 12px;

        & p {
            color: #ed1651;
        }
    }
}

small {
    display: inline;
    margin-left: 16px;
    color: #1492e6;
    cursor: pointer;
}
</style>
