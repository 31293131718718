<template>
    <div>
        <div class="fonte-secundaria voltar" @click="$emit('voltar')">
            <unicom-icone style="padding-right: 5px" textoComIcone nome="arrow-left" :tamanho="22" />
            Voltar
        </div>
        <h2 class="fonte-primaria negrito title">Escolha a Unimed</h2>

        <small class="fonte-secundaria texto subtitle">Selecione uma Unimed para continuar</small>

        <div class="button-list" v-for="(item, i) in unimedsSort()" :key="i">
            <unicom-botao tipo="primario" altura="60" bloco @click="unimedSelecionada(item)" class="botao-unimed">
                {{ item.unimed.codigo }} - {{ item.unimed.nome }}
            </unicom-botao>
        </div>
    </div>
</template>

<script>
import { sortBy } from "lodash";

export default {
    data() {
        return {
            usuarioUnimed: []
        };
    },
    created() {
        this.usuarioUnimed = this.$dadosSessao.usuarioUnimed;
    },
    methods: {
        async defineUnimeds() {
            await this.getUnimeds()
                .then(response => this.setUnimedsList(response.data))
                .catch(() => this.setUnimedsList([]));
        },

        unimedSelecionada(item) {
            this.setUnimedUsuario(item);
            this.$emit("atualizar");
            this.defineUnimeds();

            this.$emit("selecionouUnimed");
        },

        unimedsSort() {
            const sort = sortBy(this.usuarioUnimed, [i => i.unimed.codigo]);

            return sort;
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 32px;
    line-height: 55px;
    color: #00995d;
}

.voltar {
    color: #bebebe;
    font-size: 18px;
    cursor: pointer;
    width: fit-content;
}

.title {
    padding: 25px 0px;
}

.subtitle {
    color: #60656c;
    font-size: 16px;
}

.button-list {
    margin: 20px 0px;
}
</style>
