<template>
    <div class="login-container">
        <unicom-linha>
            <unicom-coluna class="container-imagem" lg="7" v-if="tamanhoJanela > 768">
                <figure>
                    <img src="../../../public/assets/thumbnail_2-01.jpg" />
                    <div class="texto-giu">
                        <div class="fonte-primaria negrito textoLogin">
                            <p>Gestão</p>
                            <p>Institucional</p>
                            <p>Unimed</p>
                        </div>
                    </div>
                </figure>
            </unicom-coluna>

            <unicom-coluna class="container-login" md="12" lg="5">
                <unicom-linha espacamento justificarItem="center">
                    <unicom-coluna v-if="listaUnimed" class="login" sm="11" md="10" lg="8">
                        <ListagemUnimeds
                            @voltar="listaUnimed = false"
                            @atualizar="$emit('atualizar')"
                            @selecionouUnimed="selecionouUnimed"
                        />
                    </unicom-coluna>

                    <unicom-coluna v-if="!listaUnimed" class="login" sm="11" md="10" lg="8">
                        <FormularioLogin
                            v-if="!semAcesso"
                            :erro="erro"
                            :mensagemErro="mensagemErro"
                            @login="usuario => entrar(usuario)"
                            @sem-acesso="fluxoSemAcesso()"
                        />
                        <EsqueciSenha v-if="semAcesso" @retornar="semAcesso = false" />
                    </unicom-coluna>
                </unicom-linha>
            </unicom-coluna>

            <unicom-coluna class="container-login" md="6" lg="5">
                <unicom-modal :value="loading" :largura="320">
                    <div slot="body">
                        <unicom-loading v-model="loading" style="margin-top:25px;" :mensagem="mensagemLoading" />
                    </div>
                </unicom-modal>
            </unicom-coluna>
        </unicom-linha>

        <ModalTermoUso
            v-model="mostrarTermoUso"
            :edicao="true"
            :conteudo="termoDeUso.conteudo"
            @termo_continuar="termosAceito()"
        />
    </div>
</template>

<script>
import EsqueciSenha from "./shared/components/EsqueciSenha";
import FormularioLogin from "./shared/components/FormularioLogin";
import ListagemUnimeds from "./shared/components/ListagemUnimed";

import ModalTermoUso from "../shared/modalTermoUso/ModalTermoUso";

import LoginServiceMixin from "./shared/services/LoginServiceMixin";

export default {
    mixins: [LoginServiceMixin],

    components: {
        FormularioLogin,
        EsqueciSenha,
        ListagemUnimeds,
        ModalTermoUso
    },
    data() {
        return {
            usuario: { login: "", senha: "" },

            erro: false,
            mensagemErro: "",

            tamanhoJanela: 0,
            semAcesso: false,
            listaUnimed: false,

            mostrarTermoUso: false,
            termoDeUso: {},

            loading: false,
            mensagemLoading: ""
        };
    },
    created() {
        this.clearStorage();

        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    mounted() {
        const urlString = window.location.href;
        const url = new URL(urlString);

        const code = url.searchParams.get("code");
        const client_id = this.$appConfig.config.CLIENT_ID_LOGIN;

        if (code) {
            const usuario = { grant_type: "authorization_code", code, redirect_uri: url.origin, client_id };

            this.entrar(usuario);
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },
    methods: {
        async entrar(usuario) {
            this.loading = true;
            this.mensagemLoading = "Autenticando...";

            await this.$nextTick();

            this.usuario = usuario;

            await this.postLogin(this.usuario)
                .then(res => {
                    this.setAuthToken(res.data);

                    if (res.data.senha_expirada) {
                        localStorage.setItem("username", usuario.username);

                        this.$router.push("/gerar-nova-senha");
                    } else {
                        this.sessao();
                    }
                })
                .catch(error => this.tratamentoErro(error));
        },
        async termos() {
            await this.getTermoUsoAceito()
                .then(() => this.sessao())
                .catch(() => this.exibeTermosUso());
        },
        async sessao() {
            await this.getDadosSessao()
                .then(res => {
                    this.setDadosSessao(res.data);
                    this.usuarioUnimed(this.$dadosSessao);
                })
                .catch(error => this.tratamentoErro(error));
        },

        async exibeTermosUso() {
            this.loading = true;
            this.mensagemLoading = "Carregando termo...";

            await this.$nextTick();

            await this.getTermoAtivo()
                .then(res => {
                    this.termoDeUso = res.data;
                    this.mostrarTermoUso = true;
                })
                .catch(error => this.tratamentoErro(error))
                .finally(() => (this.loading = false));
        },
        async termosAceito() {
            const data = {
                idTermoUso: this.termoDeUso.id,
                idLogin: this.$dadosSessao.userId,
                idUnimed: this.$dadosSessao.usuarioUnimed.unimed.id,
                idPerfil: this.$dadosSessao.usuarioUnimed.perfil.id,
                conteudo: this.termoDeUso.conteudo
            };

            await this.putTermoUso(data)
                .then(() => {
                    this.mostrarTermoUso = false;
                    this.$router.push("/home");
                })
                .catch(error => {
                    this.termoDeUso = {};
                    this.tratamentoErro(error);
                });
        },

        async defineUnimeds() {
            await this.getUnimeds()
                .then(response => this.setUnimedsList(response.data))
                .catch(() => this.setUnimedsList([]));
        },

        async usuarioUnimed(sessao) {
            this.loading = false;

            const length = sessao.usuarioUnimed.length;

            if (length > 1) {
                this.listaUnimed = true;
            } else {
                const unimed = sessao.usuarioUnimed[0];
                this.setUnimedUsuario(unimed);
                this.$emit("atualizar");

                this.defineUnimeds();

                await this.selecionouUnimed();
            }
        },
        tratamentoErro(err) {
            this.loading = false;
            this.erro = true;

            console.error(err);

            const error = err.response.data.erro;

            if (error)
                switch (error.codigo) {
                    case "GIU-000":
                    case "GIU-037":
                    case "GIU-039":
                        this.mensagemErro = error.descricao;
                        break;

                    default:
                        this.mensagemErro = "Login ou senha inválidos! <br> Por favor, tente novamente.";
                        break;
                }
            else this.mensagemErro = "Login ou senha inválidos! <br> Por favor, tente novamente.";
        },

        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        fluxoSemAcesso() {
            this.semAcesso = true;
            this.erro = false;
        },
        async selecionouUnimed() {
            await this.getTermoUsoAceito()
                .then(res => {
                    if (!res.data) {
                        this.exibeTermosUso();
                    } else this.$router.push("/home");
                })
                .catch(err => this.tratamentoErro(err));
        }
    }
};
</script>

<style lang="scss" scoped>
.textoLogin {
    font-size: 58px;
    line-height: 58px;
    color: white;
}

.texto-giu {
    position: absolute;
    top: 32%;
    left: 40%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: left;
}

.login-container {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100vh;

    .container-imagem {
        display: block;
        float: left;
        box-sizing: border-box;

        & img {
            width: 100vh;
            object-fit: fill;
        }
    }

    .container-login {
        display: block;
        box-sizing: border-box;
        float: left;
        height: 100vh;
        background-color: #fff;

        .login {
            margin: 80px 0px 243px 0;
            height: 400px;
            overflow-y: auto;
            min-height: 80vh;
        }
    }
}

@media only screen and (max-width: 575px) {
    .login {
        margin: 80px 80px 243px 0;
        height: 400px;
    }
}
</style>
