<template>
    <div>
        <Login @atualizar="$emit('atualizar')"/>
    </div>
</template>

<script>
import Login from "../components/login/Login.vue";
export default {
    components: {
        Login
    }
};
</script>

<style lang="scss"></style>
